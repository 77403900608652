@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');

.about {
    width: 100%;
    text-align: center;
}

.about-heading {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 120px;
}

.about-heading-dim {
    font-size: 60px;
    font-family: EB Garamond;
}

.about-paragraph-dim {
    font-size: 20px;
    font-family: EB Garamond;
    width: 80%;
    text-align: center;
}

.about-btns {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-buttons {
    width: 60%;
    display: flex;
    justify-content: space-around;
    padding-top: 50px;
}

.about-btn-dim {
    background-color: #004540 !important;
    font-size: 20px;
    font-family: EB Garamond;
    border: none !important;
    margin-right: 5px;
}

.about-btn-dim:hover {
    background-color: #ab8c56 !important;
    color: #004540 !important;
    transform: scale(1.2);
    transition: all 0.3s ease-in-out;
}

.about-img {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
}

.about-img-dim {
    max-width: 100%;
    max-height: 500px;
    border-radius: 25%;
    box-shadow: 0 20px 20px 20px #ab8c56;
}

.about-img-para {
    padding-top: 30px;
}

.products-imgs {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 30px;
}

.about-team-heading {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 120px;
}

.about-card {
    box-shadow: 0 20px 30px 10px #ab8c56;
    margin-bottom: 50px;
    margin: 30px;
}

@media screen  and (max-width: 991px) {
    .products-imgs {
        flex-direction: column;
    }
}