@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');

.nameste {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ab8c56;
    font-family: EB Garamond;
}

.nameste-icon {
    padding-left: 15px;
    font-size: 20px;
}