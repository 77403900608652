@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');

.home-top {
    width: 100%;
    min-height: 90vh;
    background-color: #0c5b56;
    border-radius: 0 0 30px 30px;
    box-shadow: 0 20px 20px 0 #ab8c56;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
}
  
.home-top-dim {
    max-width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
  
.home-top-logo {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.home-top-logo-dim {
    width: 50%;
    height: 50%;
}

.home-top-heading {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
  
.home-top-heading-dim {
    color: #b99960;
    font-size: 80px;
    font-family: EB Garamond;
    text-shadow: 2px 2px 20px black;
}

.home-top-paragraph-dim {
    color: #b99960;
    font-size: 30px;
    font-family: EB Garamond;
    text-shadow: 2px 2px 20px black;
    text-align: center;
}

.footer-bg {
    max-width: 100%;
    background-color: #004540;
    border-radius: 25px 25px 0 0;
}

.home-section1, .home-section2 {
    max-width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section1-img, .section2-img {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section1-img-dim, .section2-img-dim {
    width: 80%;
    height: 80%;
    border-radius: 50%;
    box-shadow: 0 20px 20px 0 #ab8c56;
}

.section1-heading, .section2-heading {
    max-width: 50%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: EB Garamond;
}

.section1-heading-dim, .section2-heading-dim {
    max-width: 40%;
    text-align: center;
    font-size: 55px;
}

.section1-paragraph-dim, .section2-paragraph-dim {
    max-width: 60%;
    text-align: center;
    font-size: 20px;
}

@media screen  and (max-width: 991px) {
    .home-top-dim {
        flex-direction: column;
    }

    .home-top-logo-dim {
        width: 100%;
        margin-bottom: 30px;
    }

    .home-top-heading-dim {
        font-size: 60px;
    }

    .home-section1, .home-section2 {
        flex-direction: column;
    }

    .section1-img, .section2-img {
        width: 80%;
        margin: 30px;
    }

    .section1-heading, .section2-heading {
        max-width: 80%;
    }

    .section1-heading-dim, .section2-heading-dim, .section1-paragraph-dim, .section2-paragraph-dim {
        max-width: 100%;
    }
}