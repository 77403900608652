@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');

.contact {
    width: 100%;
    min-height: 99vh;
    margin-top: 100px;
    margin-bottom: 50px;
  }

.contact-heading {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 30px;
}

.contact-heading-dim {
    font-size: 60px;
    font-family: EB Garamond;
}

.contact-form {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.form-dim {
    width: 40%;
    display: flex;
    align-items: center;
}

.contact-form-dim {
    width: 100%;
    font-size: 30px;
    font-family: EB Garamond;
}

.form-input {
    width: 80%;
    resize: none;
    border-color: #ab8c56 !important;
    border-width: thin !important;
}

.contact-submit-btn-dim {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-submit-btn {
    background-color: #004540 !important;
    font-size: 20px;
    border: none !important;
}

.contact-submit-btn:hover {
    background-color: #ab8c56 !important;
    color: #004540 !important;
    transform: scale(1.2);
    transition: all 0.3s ease-in-out;
}

.contact-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 50%;
}

.info-text {
    width: 100%;
    text-align: center;
    color: black;
    font-family: EB Garamond;
}

.info-heading {
    font-size: 40px;
}

.info-paragraph {
    font-size: 30px;
}

.info-para {
    color: #ab8c56;
}

.contact-icons-paragraph {
    text-align: center;
    color: black;
    font-family: EB Garamond;
    font-size: 20px;
}

.social-icons {
    text-align: center;
}

.social-btn {
    background-color: #004540 !important;
    color: #Dec587 !important;
    border: none !important;
}

.social-btn:hover {
    transform: scale(1.2);
    transition: all 0.3s ease-in-out;
}

@media screen  and (max-width: 991px) {
    .contact-form {
        flex-direction: column;
        align-items: center;
    }

    .form-dim, .contact-info {
        width: 80%;
        margin-bottom: 30px;
    }
}