@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');

.header {
  transition: all 0.5s ease-in-out;
  width: 100%;
}

.slide-up {
  transform: translateY(-100%);
}

.slide-down {
  transform: translateY(0);
}

.navbar-bg {
    background-color: #004540;
    border-radius: 0 0 25px 25px;
    box-shadow: 0 20px 30px 0 #ab8c56;
  }
  
  .nav-text {
    font-size: 30px;
    font-family: EB Garamond;
  }

  .footer-text {
    font-size: 20px;
    font-family: EB Garamond;
  }

  .logo {
    border-radius: 50%;
    box-shadow: 0 1px 15px 1px black;
  }

  .logo-text {
    font-size: 40px !important;
    font-family: EB Garamond;
    text-shadow: 2px 2px 20px black;
  }
  
  .nav-links {
    margin-right: 50px;
    color: white !important;
    text-shadow: 2px 2px 20px black;
  }
  
  .nav-link:hover {
    color: #b99960 !important;
    transition: all 0.3s ease-in-out;
  }